import React from 'react'

const ArrowRightIcon = ({ color, w }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill={color} width={w} ><g>
      <path fill="none" d="M0 0h24v24H0z"></path>
      <path d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"></path>
    </g>
    </svg>
  )
}

export default ArrowRightIcon