import React from 'react'

const FormPage = () => {
  return (
   <div className='w-full'>
     <div id="_lg_form_" />
   </div>
  )
}

export default FormPage